import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, useStaticQuery, Link } from "gatsby";
import LinkElement from "components/LinkElement";
/**
 * Single Card Component
 * @type {React.FC<{logo: {src: string, alt: string}, phone: {label: string, link: string}, email: {label: string, link: string}, social: Array<{icon: string, label: string, library: string, link: string}>, columns: Array<{id: string, label: string, items: Array<{id: string, label: string, link: string, library: string, icon: string}>}>}
 */
const Footer = ({ logo, phone, email, social, columns }) => {
  const footerWaveSvgData = useStaticQuery(graphql`
    query MyQuery2 {
      allFile(filter: { relativePath: { eq: "footer-wave.svg" } }) {
        nodes {
          publicURL
        }
      }
    }
  `);

  return (
    <div className="bg-secondary relative">
      {footerWaveSvgData.allFile.nodes.length && (
        <div
          className={`absolute top-0 left-0 h-full w-full bg-no-repeat bg-contain`}
          style={{
            backgroundImage: `url('${footerWaveSvgData.allFile.nodes[0].publicURL}')`,
            backgroundPosition: ""
          }}
        />
      )}
      <div className="relative max-w-7xl px-5 mx-auto py-24 z-[1] grid md:grid-cols-2 gap-10">
        <div className="text-gray-400 flex flex-col gap-4">
          {/* logo  */}
          <Link className="w-full max-w-[180px]" to="/">
            <img src={logo.src} alt={logo.alt} />
          </Link>

          {/* phone */}
          <div className="flex flex-row gap-2 items-center mt-3">
            <FontAwesomeIcon icon="fa-solid fa-phone" />
            <a href={phone.link}>{phone.label}</a>
          </div>

          {/* email */}
          <div className="flex flex-row gap-2 items-center">
            <FontAwesomeIcon icon="fa-solid fa-envelope" />
            <a href={email.link}>{email.label}</a>
          </div>

          {/* social icons */}
          {social && (
            <div className="flex flex-row gap-5">
              {social.map((item) => {
                return (
                  <a
                    key={item.id}
                    href={item.link}
                    className="text-2xl"
                    target="_blank"
                    rel="noreferrer">
                    <FontAwesomeIcon icon={item.icon} />
                  </a>
                );
              })}
            </div>
          )}
        </div>

        {/* link columns */}
        <div className="grid grid-cols-2 gap-5">
          {columns &&
            columns.map((col) => {
              return (
                <div key={col.id}>
                  <p className="text-xl text-white relative pb-3 mb-5 after:absolute after:bottom-0 after:left-0 after:content-[''] after:h-[7px] after:w-[50px] after:bg-primary after:rounded-badge">
                    {col.label}
                  </p>
                  <ul className="text-gray-400 list-none">
                    {col.items &&
                      col.items.map((item) => {
                        return (
                          <li key={item.id}>
                            <LinkElement href={item.link} className="inline-block py-1">
                              {item.label}
                            </LinkElement>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
