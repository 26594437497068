import "./src/styles/global.css";

/** @type {import('gatsby').GatsbyBrowser["wrapPageElement"]} */
// export const wrapPageElement = (props) => {
//   return <main className="max-w-7xl px-5 mx-auto">{props.element}</main>;
// };

/** @type {import('gatsby').GatsbyBrowser["onRouteUpdate"]} */
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: "instant" }), 0);
  }
};

/** @type {import('gatsby').GatsbyBrowser["shouldUpdateScroll"]} */
export const shouldUpdateScroll = (props) => {
  const {
    getSavedScrollPosition,
    routerProps: { location }
  } = props;

  window.history.scrollRestoration = "manual";
  const currentPosition = getSavedScrollPosition(location) || [0, 0];

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`);
      item?.scrollIntoView({ behavior: "smooth" });
    } else {
      // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
      window.scrollTo({
        top: currentPosition[1],
        left: currentPosition[0],
        behavior: "instant"
      });
    }
  }, 0);

  // do not let gatsby do anything more
  return false;
};
