import React from "react";
import { Link } from "gatsby";

const LinkElement = ({ href, target, children, ...props }) => {
  return target ||
    href?.startsWith("http://") ||
    href?.startsWith("https://") ||
    href?.startsWith("www.") ? (
    <a href={href} target={target ? "_blank" : "_self"} rel="noreferrer" {...props}>
      {children}
    </a>
  ) : (
    <Link to={href} {...props}>
      {children}
    </Link>
  );
};

export default LinkElement;
