import React, { useState } from "react";
import { Link } from "gatsby";
import MobileMenu from "./MobileMenu";
import LinkElement from "components/LinkElement";
import { Bars3BottomRightIcon } from "@heroicons/react/24/outline";

export default function Header({ logo, menu, button }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <nav className="bg-white relative mb-2">
        <div className="relative max-w-7xl p-5 mx-auto z-[1] flex gap-10 justify-between">
          {/* logo */}
          <Link to="/">
            <img src={logo.src} alt={logo.alt} className="h-10 w-auto" />
          </Link>

          <div className="flex-row justify-evenly gap-8 items-center hidden md:flex">
            {menu?.map((item, index) => {
              return (
                <LinkElement
                  key={index}
                  href={item.link}
                  className="font-normal text-lg text-dark-gray block"
                  activeClassName="text-primary"
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}>
                  {item.label}
                </LinkElement>
              );
            })}
          </div>

          <div className="hidden md:block">
            <LinkElement
              href={button?.link}
              className="px-5 py-2 bg-primary text-white inline-block rounded">
              {button?.label}
            </LinkElement>
          </div>
          <button
            className="inline-block md:hidden"
            onClick={(e) => {
              e.preventDefault();
              setMobileMenuOpen(true);
            }}>
            <Bars3BottomRightIcon height="100%" width={30} />
          </button>
        </div>
      </nav>
      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        logo={logo}
        menu={menu}
        button={button}
      />
    </>
  );
}
