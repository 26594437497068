import Footer from "components/Footer";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faEnvelope,
  faCode,
  faBullhorn,
  faFingerprint,
  faSquarePollVertical,
  faBrain,
  faChess,
  faUsers,
  faChevronCircleUp
} from "@fortawesome/free-solid-svg-icons";
import Header from "components/Header";
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add([
  faPhone,
  faEnvelope,
  faCode,
  faBullhorn,
  faFingerprint,
  faSquarePollVertical,
  faBrain,
  faChess,
  faUsers,
  faFacebook,
  faInstagram,
  faLinkedin
]);

// TODO: only import required icons, otherwise the build size will increased
// import { library } from "@fortawesome/fontawesome-svg-core";
// import * as solidIcons from "@fortawesome/free-solid-svg-icons";
// import * as brandsIcon from "@fortawesome/free-brands-svg-icons";

// // Get all icons from the solidIcons object and add them to the library
// Object.keys(solidIcons).forEach((iconName) => {
//   if (["prefix", "fas"].includes(iconName)) return;
//   library.add(solidIcons[iconName]);
// });
// // Get all icons from the brandsIcon object and add them to the library
// Object.keys(brandsIcon).forEach((iconName) => {
//   if (["prefix", "fas"].includes(iconName)) return;
//   library.add(brandsIcon[iconName]);
// });

const Layout = ({ children }) => {
  // fetch layout data from strapi
  const data = useStaticQuery(graphql`
    query getLayoutData {
      strapiLayout {
        header {
          button {
            label
            link
            variant
          }
          logo {
            alternativeText
            file {
              publicURL
            }
          }
          menu {
            icon
            label
            library
            link
            id
          }
        }
        footer {
          logo {
            alternativeText
            file {
              publicURL
            }
          }
          email {
            link
            label
            library
            icon
          }
          phone {
            label
            link
            library
            icon
          }
          columns {
            id
            label
            items {
              id
              label
              link
              library
              icon
            }
          }
          social {
            id
            label
            icon
            link
            id
            library
          }
        }
      }
    }
  `);

  return (
    <div>
      <Header
        logo={{
          src: data.strapiLayout.header.logo.file.publicURL,
          alt: data.strapiLayout.header.logo.alternativeText
        }}
        menu={data.strapiLayout.header.menu}
        button={data.strapiLayout.header.button}
      />
      <main className="flex flex-col lg:mt-6 mx-auto" style={{ gap: "5rem" }}>
        {children}
      </main>
      <Footer
        logo={{
          src: data.strapiLayout.footer.logo.file.publicURL,
          alt: data.strapiLayout.footer.logo.alternativeText
        }}
        phone={data.strapiLayout.footer.phone}
        email={data.strapiLayout.footer.email}
        social={data.strapiLayout.footer.social}
        columns={data.strapiLayout.footer.columns}
      />
      <ScrollToTop
        smooth
        component={<FontAwesomeIcon icon={faChevronCircleUp} />}
        className="text-secondary text-xl"
        top={200}
      />
    </div>
  );
};

export default Layout;
