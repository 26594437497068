import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import LinkElement from "components/LinkElement";
import React, { Fragment } from "react";
const MobileMenu = ({ mobileMenuOpen, setMobileMenuOpen, logo, menu, button }) => {
  return (
    <Transition.Root show={mobileMenuOpen} as={Fragment}>
      <Dialog as="div" className="lg:hidden relative z-50" onClose={setMobileMenuOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className=" pointer-events-none fixed inset-y-0 right-0 flex max-w-full ps-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="-translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="-translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute top-0 right-0 mr-8 flex pt-6 pl-2 sm:mr-10 sm:pl-4">
                      <button
                        type="button"
                        className="rounded-md text-dark-gray text-2xl focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}>
                        <span className="sr-only">Close panel</span>
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className="flex h-full flex-col bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6 outline-none">
                      <Dialog.Title
                        className="text-lg font-medium text-gray-900 outline-none"
                        as={LinkElement}
                        href="/">
                        {/* <SvgElement src={logo} className="h-10 w-auto fill-black" /> */}
                        <img src={logo.src} alt={logo.alt} className="h-10 w-auto" />
                        <br />
                      </Dialog.Title>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6 overflow-y-auto overflow-x-hidden">
                      <div className="flex justify-between flex-col h-full ">
                        <div className="mt-2 flex flex-col gap-5 ps-4">
                          {menu?.map((item, index) => {
                            return (
                              <LinkElement
                                key={index}
                                href={item.link}
                                className="font-normal text-xl text-dark-gray block"
                                onClick={() => {
                                  setMobileMenuOpen(false);
                                }}>
                                {item.label}
                              </LinkElement>
                            );
                          })}
                        </div>

                        <div>
                          <LinkElement
                            href={button?.link}
                            className="px-5 py-2 bg-primary text-white inline-block rounded">
                            {button?.label}
                          </LinkElement>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileMenu;
